import { Component }                                    from '@angular/core';
import { UserService }                                  from '../core/user/user.service';
import { Router }                                       from '@angular/router';
import { FormBuilder, FormGroup, Validators }           from '@angular/forms';
import { ABErrorStateMatcher, getErrorMessageFromData } from '../shared/utils/helpers';
import { MeGQL, UserSignInGQL }                         from '../../generated/graphql';
import { MatSnackBar }                                  from '@angular/material/snack-bar';
import { TranslatePipe }                                from '@ngx-translate/core';

@Component({
  selector: 'et-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [TranslatePipe]
})
export class SignInComponent {

  signInForm: FormGroup;
  busy = false;
  hidePassword = true;
  errorMessage: any;
  errorStateMatcher = new ABErrorStateMatcher();

  constructor(private userSignInGQL: UserSignInGQL,
              private meGQL: MeGQL,
              private userService: UserService,
              private router: Router,
              private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private formBuilder: FormBuilder) {
    this.signInForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    }, {updateOn: 'change'});

  }

  private onSuccessfulLogin(res: any): void {
    this.userService.requestOverlay();
    UserService.setHeaders(res.data.userSignIn.authHeaders);
    this.userService.setUser(res.data.userSignIn.user);
    window.location.reload();
  }

  private onUnsuccessfulLogin(err: any): void {
    this.snackBar.open(getErrorMessageFromData(err), this.translatePipe.transform('notification-box.close'), {
      duration: 4000
    });
    this.busy = false;
  }


  onSubmit(userData: any): void {
    this.busy = true;
    this.errorMessage = null;
    this.userSignInGQL.mutate({
      input: {
        email: {
          email: userData.email,
          password: userData.password
        }
      }
    }, {fetchPolicy: 'no-cache'}).subscribe(res => this.onSuccessfulLogin(res), err => this.onUnsuccessfulLogin(err));
  }

}
