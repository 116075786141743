<div class="unauth-area sign-in">
  <div class="unauth-area__form-wrapper">
    <form [formGroup]="signInForm" class="unauth-area__form sign-in__form"
          (ngSubmit)="signInForm?.valid && onSubmit(signInForm.value)">
      <h1>{{'sign-in.title' | translate}}</h1>
      <label>{{'sign-in.username' | translate}}</label>
      <mat-form-field appearance="outline">
        <input type="email"
               matInput
               name="email"
               formControlName="email"
               [placeholder]="('sign-in.username' | translate)"
               [autocomplete]="('sign-in.username' | translate)"
               [errorStateMatcher]="errorStateMatcher"
               autofocus/>
        <mat-error
          *ngIf="signInForm.get('email')?.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        <mat-error
          *ngIf="signInForm.get('email')?.hasError('email')">{{'form-validation.email-invalid' | translate}}</mat-error>
      </mat-form-field>
      <label>{{'sign-in.password' | translate}}</label>
      <mat-form-field appearance="outline">
        <input matInput
               name="password"
               formControlName="password"
               [placeholder]="('sign-in.password' | translate)"
               [autocomplete]="('sign-in.password' | translate)"
               [errorStateMatcher]="errorStateMatcher"
               (focus)="errorMessage = undefined"
               [type]="hidePassword ? 'password' : 'text'"/>
        <span class="cursor-pointer" matSuffix (click)="hidePassword = !hidePassword">
                  <ng-container *ngIf="hidePassword"><mat-icon aria-hidden="false"
                                                               aria-label="mostra password">visibility</mat-icon></ng-container>
                  <ng-container *ngIf="!hidePassword"><mat-icon aria-hidden="false" aria-label="nascondi password">visibility_off</mat-icon></ng-container>
                </span>
        <mat-error
          *ngIf="signInForm.get('password')?.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        <mat-hint class="custom-validation-error" *ngIf="errorMessage">{{errorMessage}}</mat-hint>
      </mat-form-field>
      <button type="submit"
              *ngIf="!busy"
              [tabIndex]="(!signInForm.get('email')?.valid || !signInForm.get('password')?.valid) ? -1 : 0"
              [disabled]="!signInForm.get('email')?.valid || !signInForm.get('password')?.valid"
              mat-stroked-button
              color="primary"
              class="mat-elevation-z1 big-button sign-in__form__submit">{{'sign-in.submit' | translate}}</button>
      <et-loading *ngIf="busy"></et-loading>
    </form>
  </div>
</div>
