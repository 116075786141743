<div class="header">
  <div class="header__images">
    <img alt="logo entity" src="../../assets/images/entity-logo-header.svg" routerLink="/" class="cursor-pointer"/>
  </div>
  <nav class="header__menu">
    <ul>
      <li *ngFor="let link of menuLinks"
          [routerLink]="link.routerLink"
          class="header__menu-link"
          routerLinkActive="header__menu-link--active">
        <a>{{link.labelToTranslate | translate}}</a>
      </li>
    </ul>
    <!--       [class.header__avatar&#45;&#45;with-image]="userService.user?.profileImage?.url"-->
    <!--       [style.background-image]="'url('+userService.user?.profileImage?.url+')'"-->
    <div class="header__avatar"
         [matMenuTriggerFor]="userMenu">
      <!--    *ngIf="!userService.user?.profileImage?.url"-->
      <div class="header__avatar__image"
           [style.background-image]="'url(../../../assets/images/profile-placeholder.svg)'"></div>
    </div>
    <mat-menu class="header-menu" #userMenu="matMenu">
      <p style="padding: 16px">{{userEmail}}</p>
      <button mat-menu-item (click)="onSignOutClick()">{{'header.sign-out' | translate}}</button>
    </mat-menu>
  </nav>
</div>
